import { EventEmitter, Injector }                           from '@angular/core';
import { TranslateService }                                 from '@ngx-translate/core';
import { DynamicButtonRegistration, DynamicButtonsAgent }   from '../pm-dynamic-button-bar/dynamic-button-bar.agent';
import { BottomBarRequestActions, BottomBarService }        from './state/bottom-bar.service';
import { DynamicButtonBarClickedEventArgs }                 from '../pm-dynamic-button-bar/dynamic-button-bar.event-args';
import { CsButtonBarComponent }                             from './cs-button-bar.component';
import { BottomBarQuery }                                   from './state/bottom-bar.query';
import { getErrorMessages, getErrorMessagesFromValidators } from '@cs/core';

export class DefaultPageBottomButtonsAgent extends DynamicButtonsAgent {

	setupButtons(): void {
		this.addButton(DynamicButtonRegistration.createClientButton({
																																																															buttonId:      'Cancel',
																																																															buttons:       [
																																																																{
																																																																	label:    'Cancel',
																																																																	name:     'cancel',
																																																																	btnClass: 'btn-quaternary btn-md m-0 mr-2'
																																																																}
																																																															],
																																																															buttonsStates: [
																																																																{
																																																																	from:              'cancel', to: 'cancel',
																																																																	executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																																																																		const bottomBarService       = injector.get(BottomBarService);
																																																																		const onDynamicButtonClicked = new EventEmitter<DynamicButtonBarClickedEventArgs>();

																																																																		onDynamicButtonClicked.emit(new DynamicButtonBarClickedEventArgs(button));
																																																																		bottomBarService.onButtonClicked.next(new DynamicButtonBarClickedEventArgs(button));
																																																																		bottomBarService.requestAction.next({action: BottomBarRequestActions.RESET_BAR});
																																																																	}
																																																																}
																																																															]
																																																														}));

		this.addButton(DynamicButtonRegistration.createClientButton({
																																																															buttonId:      'Save',
																																																															buttons:       [
																																																																{
																																																																	label:    'Save',
																																																																	name:     'save',
																																																																	btnClass: 'btn-primary btn-md m-0'
																																																																}
																																																															],
																																																															isDisabled:    (button: DynamicButtonRegistration, injector: Injector, component: CsButtonBarComponent) => {
																																																																const buttonBarQuery = injector.get(BottomBarQuery);
																																																																const i8n            = injector.get(TranslateService);

																																																																if (!buttonBarQuery.getValue().dataIsValid) {
																																																																	button.messages = [i8n.instant('MESSAGES.INVALID_DATA')];
																																																																} else {
																																																																	const errors    = getErrorMessagesFromValidators(component.reasonField, buttonBarQuery.getValue().inputValidators);
																																																																	button.messages = errors.map(value => value.errorMessage);
																																																																}

																																																																return (!buttonBarQuery.getValue().dataIsValid)
																																																																	|| (component.hasInputOptions && (component.reasonField.invalid
																																																																		|| (buttonBarQuery.getValue().inputRequired && component.reasonField.pristine)));
																																																															},
																																																															buttonsStates: [
																																																																{
																																																																	from:              'save', to: 'save',
																																																																	executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																																																																		const bottomBarService       = injector.get(BottomBarService);
																																																																		const onDynamicButtonClicked = new EventEmitter<DynamicButtonBarClickedEventArgs>();

																																																																		onDynamicButtonClicked.emit(new DynamicButtonBarClickedEventArgs(button));
																																																																		bottomBarService.onButtonClicked.next(new DynamicButtonBarClickedEventArgs(button));
																																																																	}
																																																																}
																																																															]
																																																														}));

	}

}
